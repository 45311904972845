<template>
  <section>
    <div v-if="appointments && appointments.length > 0">
      <div v-for="(item, i) in appointments" :key="i">
        <v-card class="p-3 bg-white rounded my-5 d-flex flex-row align-items-center" flat
                v-for="(itemDetails, i) in item.timeSheetDetails" :key="i">
          <v-avatar size="50" v-if="itemDetails.userAppointment.clientUserPic">
            <img :src="itemDetails.userAppointment.clientUserPic" alt="Appointment-logo"/>
          </v-avatar>
          <b-icon icon="person" width="50" height="50" class="bg-light pa-4 rounded-circle" variant="info"
                  v-else></b-icon>
          <div class="ms-3">
          <span class="d-block f15 fw-bold">
            <span>
              {{ $t('APPOINTMENT') }}
            </span>
              <span>
                {{ $t(itemDetails.userAppointment.sessionType) }}
              </span>
          </span>

            <span class="d-block f13">{{ item.doctorFullName }}</span>
            <div class="d-flex justify-content-between f13">
            <span class="grey--text">
              {{ item.valueDate | moment("dddd , jD jMMMM jYYYY") }}
            </span>
              <span class="mx-1 grey--text">|</span>
              <span dir="ltr" class="grey--text">
              {{ itemDetails.fromTime }}
              -
              {{ itemDetails.toTime }}
            </span>
            </div>
            <div>
              <p class="f15 fw-bold py-0 my-0">{{$t("ShowDescriptionUser")}}</p>
              <span>{{itemDetails.description?itemDetails.description:'-'}}</span>
            </div>

            <div>
            <span :class="itemDetails.userAppointment.paid ? 'text-success' : 'text-danger'">
              {{ item.consultantAmount.toLocaleString() }}
              {{ $t("RIAL") }}
            </span>
            </div>

            <div class="mt-2 ms-0">
              <v-btn class="rounded-lg" elevation="0" color="success" v-if="!itemDetails.userAppointment.paid"
                     @click="handlePay(item, itemDetails)">{{ $t("Pay") }}
              </v-btn>
              <v-btn v-else outlined text elevation="0" color="success">{{ $t("PAID") }}</v-btn>
              <v-dialog v-model="cancelAppointment" max-width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ms-2 rounded-lg"
                         text elevation="0" v-on="on" v-bind="attrs">
                    {{
                      $t("USER_APPOINTMENT_CANCEL")
                    }}
                  </v-btn>
                </template>

                <v-card class="text-center pa-4">
                  <v-card-title class="f16 font-weight-bold d-block">{{
                      $t("CANCEL_RESERVED_APPOINTMENT")
                    }}</v-card-title>
                  <v-divider/>
                  <v-card-text class="f14 p-2 font-weight-light">
                    <p>
                      {{
                        $t(
                            "Please contact the support team for cancellation"
                        )
                      }}
                    </p>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                        @click="cancelAppointment = false"
                        color="warning"
                        block
                        class="mb-2"
                    >{{ $t("I Understand") }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <span v-else class="text-center f16 d-block mt-8">
      {{ $t('No Appointments found') }}
    </span>
  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";

moment.updateLocale("fa", fa);
moment.loadPersian();

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
});
export default {
  name: "Appointments",
  data() {
    return {
      currencyMask,
      submit_loading: false,
      cancelAppointment: false,
      appointments: [],
      appointmentProp: {},

      page: 1,
      size: 12,
    };
  },
  methods: {
    getAppointments(fromDate, toDate) {
      apiService.gatAppointmentsList(fromDate, toDate)
          .then((response) => {
            this.appointments = response.data.appointments;
          });
    },
    handlePay(item, itemDetails) {
      this.getClientAccountBalance(item, itemDetails);
    },
    getClientAccountBalance(item, itemDetails) {
      apiService.getClientAccountBalance()
          .then((response) => {
            console.log(response)
            const accountBalance = response.data;
            if (accountBalance.cash >= (item.consultantAmount || 0)) {
              const paymentDTO = {
                drUsername: item.drUsername,
                finalAmount: (item.consultantAmount || 0),
                originalAmount: (item.consultantAmount || 0),
                paymentReason: "CONSULTANT",
                reason: "CONSULTANT",
                appointmentId: itemDetails.userAppointment.id
              };

              apiService.paymentWithWallet(paymentDTO)
                  .then((response) => {
                    if (response.data.code == "0") {
                      this.$swal({
                        icon: "success",
                        title: this.$t("Success"),
                        text: this.$t("The consultant fee has been paid successfully"),
                        confirmButtonText: this.$t('Continue'),
                      })
                          .then((result) => {
                            if (result.isConfirmed) {
                              this.getAppointments('2023-11-02', '2028-12-29');
                            }
                          });
                    } else {
                      this.$swal({
                        icon: "warning",
                        text: this.$t("Error in paying the exam fee"),
                        confirmButtonText: this.$t('OK'),
                      });
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    this.$swal({
                      icon: "warning",
                      text: this.$t("Error in paying the consultant fee"),
                      confirmButtonText: this.$t('OK'),
                    });
                  });
            } else {
              this.$swal({
                icon: "warning",
                title: this.$t("Wallet balance is not enough"),
                text: this.$t("Your wallet balance is not enough. Please increase the inventory"),
                confirmButtonText: this.$t('Increase wallet balance'),
                showDenyButton: true,
                denyButtonText: this.$t('Cancel')
              })
                  .then((result) => {
                    if (result.isConfirmed) {
                      this.$router.push('/c/profile/wallet');
                    }
                  });
            }

          })
          .catch((err) => {
            console.log(err);
            this.$swal({
              icon: "warning",
              text: this.$t("Error receiving wallet information"),
              confirmButtonText: this.$t('OK'),
            });
          });
    },
    infiniteHandler($state) {
      apiService.gatAppointmentsList('2023-11-02', '2028-12-29')
          .then((response) => {
            if (response.data.content.length > 0) {
              response.data.content.forEach(element => {
                this.appointments.push(element);
              });
              this.page += 1;
              $state.loaded();
            } else {
              $state.complete();
            }
          })
          .catch(() => {
            $state.error();
          });
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
    this.getAppointments('2023-11-02', '2028-12-29');
  },
  watch: {
    selectedAppointmentDate: function (newVal) {
      apiService.getDoctorTimesheetDetailsByDoctorIdAndDateAndSessionType(
          this.$route.params.id,
          newVal,
          this.appointmentType
      )
          .then((response) => {
            this.selectedAppointmentDateInPersian = moment(newVal).locale('fa').format('jYYYY/jMM/jDD');
            if (response.data.id) {
              this.timeSheetDetails = response.data.timeSheetDetails
                  .slice()
                  .sort(function (a, b) {
                    return a.fromTime.localeCompare(b.fromTime);
                  });
              if (!this.closestTime && this.timeSheetDetails?.length > 0) {
                const closestTime = this.timeSheetDetails.find(
                    (item) => {
                      item.fromTime.split(":")[0] > moment().hour() &&
                      !item.userAppointment
                      console.log(item.fromTime)
                    }
                );
                this.closestTimeId = closestTime.id;
                this.closestButtonString = `${moment(
                    response.data.valueDate
                ).format("dddd jD jMMMM")} ${
                    closestTime.fromTime.split(":").slice(0, 2).join(":")
                } - ${closestTime.toTime.split(":").slice(0, 2).join(":")}`;
              }
            } else {
              this.timeSheetDetails = [];
              if (!this.closestTime) {
                apiService
                    .getClosestEmptyTimesheet(
                        this.$route.params.id,
                        this.appointmentType
                    )
                    .then((response) => {
                      const closestTime = response.data;
                      if (closestTime) {
                        this.closestTimeId = closestTime.timesheetDetailId;
                        this.closestButtonString = `${moment(
                            closestTime.valueDate
                        ).format("dddd jD jMMMM")} ${
                            closestTime.fromTime.split(":").slice(0, 2).join(":")
                        } - ${closestTime.toTime.split(":").slice(0, 2).join(":")}`;
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                    });
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
}
</script>

<style scoped>

</style>